<template>
  <div>
    <div>
      <Form :model="queryinfo" :label-width="80">
        <Row>
          <FormItem label="机器ID">
            <Input v-model="queryinfo.mid" placeholder="请输入机器ID" clearable></Input>
          </FormItem>

          <FormItem>
            <Select v-model="queryinfo.type" style="width:100px" placeholder="选择Type" clearable>
              <!-- <Option value=" "></Option> -->
              <Option :value="0">Up</Option>
              <Option :value="1">Down</Option>

            </Select>
          </FormItem>

          <FormItem>
            <DatePicker type="daterange" placement="bottom-end" placeholder="选择日期范围" style="width: 200px"
                        @on-change="onDateSelChange" />
          </FormItem>

          <FormItem>
            <Button type="primary" @click="onSearch">查询</Button>
          </FormItem>
        </Row>
      </Form>


    </div>

    <div>
      <Table :loading="loading" stripe border ref="selection" size="small" :columns="columns" :data="data"
             @on-selection-change="onHandleSelectRow">
      </Table>
    </div>

    <!-- 分页 -->
    <div style="padding: 15px 5px;">
      <Page :current="queryinfo.page_num" :total="totalCount" :page-size="queryinfo.page_size"
            :page-size-opts="pagesizeopts" show-sizer show-total style="text-align: center" @on-change="onChangePage"
            @on-page-size-change="onChangePageSize" />
    </div>
  </div>
</template>

<script>
import { ckquery, get, post, getDialLogAPIUrl } from "@/api/http"
import Notice from "@/components/baseNotice"
export default {
  data() {
    let columns = [
      // {
      //         type: 'selection',
      //         width: 60,
      //         align: 'center'
      //     },
      {
        title: '机器ID',
        key: 'mid',
        width: 300,
        align: 'left',
      },
      {
        title: '网卡',
        key: 'ifcname',
        width: 120,
        align: 'left',
      },
      {
        title: '类型',
        key: 'type',
        width: 80,
        align: 'left',
        render: (h, { row }) => {
          return h('span', row.type == 1 ? "down" : "up");
        },
      },
      {
        title: '本地IP',
        key: 'lip',
        width: 180,
        align: 'left',
      },
      {
        title: '远端IP',
        key: 'rip',
        width: 180,
        align: 'left',
      },
      {
        title: '时间',
        key: 'trig_time',
        align: 'left',
        render: (h, { row }) => {
          return h('span', this.formatTimestamp(row.trig_time));
        },
      },
    ];
    return {
      loading: false,
      columns: columns,
      data: [],
      queryinfo: {
        mid: '',
        type: -1,
        from: 0,
        to: 0,
        page_size: 20,
        page_num: 1,
      },
      totalCount: 0,
      pagesizeopts: [20, 30, 50, 100],
    }

  },
  methods: {
    onHandleSelectRow() {

    },

    onSearch() {
      this.fetchLogs();
    },
    onResetSearch() {

    },
    onChangePage(page) {
      this.queryinfo.page_num = page;
      this.fetchLogs();

    },
    onChangePageSize(pagesize) {
      this.queryinfo.page_size = pagesize;
      this.fetchLogs();
    },

    onDateSelChange(dates) {
      if (!dates || dates.length == 0) {
        this.queryinfo.from = 0;
        this.queryinfo.to = 0;
      } else {
        this.queryinfo.from = new Date(dates[0].replace(/-/g, '/')).getTime();
        if (dates.length > 1) {
          this.queryinfo.to = new Date(dates[1].replace(/-/g, '/')).getTime();
        }
      }
    },

    fetchLogs() {
      this.loading = true;
      post("/api/v1/query-logs", this.queryinfo, true)
          .then((resp) => {
            //console.log('got resp:', resp);
            if (resp.err_code === 0) {
              this.data = resp.data.items || [];
              this.totalCount = resp.data.total;
            } else {
              Notice.error(`请求失败(${resp.err_code})`);
            }
            this.loading = false;
          })
          .catch((err) => {
            Notice.error(`请求失败${err}`);
            this.loading = false;
          });
    },

    formatTimestamp(t, toSeconds) {
      if (!t) {
        return '';
      }
      var date = new Date(t);
      var Y = date.getFullYear() + "-";
      var M = ((date.getMonth() + 1) + '').padStart(2, '0') + '-';
      var D = (date.getDate() + '').padStart(2, '0') + " ";
      var h = (date.getHours() + '').padStart(2, '0') + ":";
      var m = (date.getMinutes() + '').padStart(2, '0');
      if (toSeconds) {
        var s = (date.getSeconds() + '').padStart(2, '0');
        return Y + M + D + h + m + ':' + s;
      }
      return Y + M + D + h + m;
    }
  },
  created() {
    this.fetchLogs();
  }
}
</script>
